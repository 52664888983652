import { useEffect } from 'react';

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0
};


export default (map?: google.maps.Map<Element>) => {
  useEffect(() => {
    let id: number;
    if ('geolocation' in navigator && map) {
      navigator.geolocation.getCurrentPosition(position => {
        const marker = new google.maps.Marker({
          position: {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          },
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 5,
            fillColor: 'green',
            strokeColor: 'green',
          },
          map
        });
        id = navigator.geolocation.watchPosition(position => marker.setPosition({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        }), undefined, options);
      }, undefined, options);
    }
    return () => {
      navigator.geolocation.clearWatch(id);
    }
  }, [map])
}