import React from 'react';
import ReactDOM from 'react-dom';
import '@aws-amplify/ui/dist/style.css';
import '@fortawesome/fontawesome-free/css/all.css';
import 'react-toastify/dist/ReactToastify.css';

import App from './app';
import './theme/main.min.css';


ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);
