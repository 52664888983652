import React, { useState, useMemo, useEffect, useCallback, useReducer, useContext } from 'react';
import { DeliveryTime, DDeliveryStatus } from '@calo/types';
import { DeliveryFilters, Delivery, LatLng } from '@calo/driver-types';
import { format } from 'date-fns/fp';
import { omit } from 'lodash-es';
import { useQuery } from 'react-query';

import { ViewMode } from '../../lib/enums';
import DeliveriesMapView from './DeliveriesMapView';
import DeliveriesListView from './DeliveriesListView';
import Filters from './Filters';
import { TopBar, Modal } from '../../components';
import { getList, updateDelivery, updateAddress, addToWallet } from '../../actions';
import DeliveryContext from './DeliveryContext';
import deliveryReducer from './deliveryReducer';
import deliverySelector from './deliverySelector';
import NewPaymentForm from './NewPaymentForm';
import ConfirmContext from '../../components/Confirmation/ConfirmContext';

const Deliveries = () => {
  const [viewMode, setViewMode] = useState(localStorage.getItem('viewMode') as ViewMode || ViewMode.map)
  const [modal, setModal] = useState<Delivery | null>()
  // const user = useCurrentUser();

  const [filters, setFilters] = useState<DeliveryFilters>({
    day: format('yyyy-MM-dd')(Date.now()),
    deliveryTime: DeliveryTime.morning,
    ...(localStorage.getItem('filters') ? JSON.parse(localStorage.getItem('filters')!) : {})
  });

  const confirm = useContext(ConfirmContext);

  const [state, dispatch] = useReducer(deliveryReducer, { list: [], keyedList: {}, selected: null });

  const { data } = useQuery(['/deliveries', { day: filters.day }], getList, {
    refetchOnWindowFocus: false,
    refetchIntervalInBackground: false
  });


  useEffect(() => {
    const { day, ...rest } = filters;
    localStorage.setItem('viewMode', viewMode);
    localStorage.setItem('filters', JSON.stringify(rest));
    dispatch({ type: 'select', payload: null })
  }, [viewMode, filters])

  useEffect(() => {
    if (data) {
      dispatch({ type: 'set', payload: data })
    }
  }, [data])


  
  const handleDelivering = useCallback(async (delivery: Delivery) => {
    const updated = await updateDelivery(delivery.id, {
      deliveryStatus: DDeliveryStatus.delivering
    });

    dispatch({
      type: 'update',
      payload: updated
    })
  }, []);

  const handleAddressChange = useCallback((delivery: Delivery, latLng: LatLng) => updateAddress(delivery.userId, latLng), []);

  const handleModalSubmit = useCallback(async (delivery: Delivery, amount: number) => {
    let updated: Partial<Delivery> = {
      id: delivery.id
    }

    if (delivery.deliveryStatus !== DDeliveryStatus.delivered) {
      const data = await updateDelivery(delivery.id, {
        deliveryStatus: DDeliveryStatus.delivered,
      });
      updated = {
        ...updated,
        ...data
      }
    }

    if (amount > 0) {
      await addToWallet(delivery.userId, amount);
      updated = {
        ...updated,
        pendingAmount: Math.max(0, (delivery.pendingAmount || 0) - amount)
      }
    }


    dispatch({
      type: 'update',
      payload: {
        id: delivery.id,
        ...updated,
        pendingAmount: Math.max(0, (delivery.pendingAmount || 0) - amount)
      }
    });
    setModal(null)

    if (delivery.deliveryStatus !== DDeliveryStatus.delivered) {
      navigator.geolocation.getCurrentPosition(position => {
        if (google.maps.geometry.spherical.computeDistanceBetween(
          new google.maps.LatLng(position.coords.latitude, position.coords.longitude),
          new google.maps.LatLng(delivery.deliveryAddress.lat, delivery.deliveryAddress.lng)) > 30) {
          confirm({
            text: `We're notice that delivery location might be wrong, are you currently at delivery drop point?`,
            confirm: handleAddressChange.bind(null, delivery, { lat: position.coords.latitude, lng: position.coords.longitude })
          })
        }
      }, console.error, {
        enableHighAccuracy: false,
        timeout: 5000,
        maximumAge: 0
      })
    }

  }, []);


  const { list, keyedList, selected } = state;
  const deliveryList = useMemo(() => list.map(id => keyedList[id]), [keyedList]);

  const deliveries = deliverySelector(deliveryList, filters)

  const delivered = deliverySelector(deliveryList, {
    ...filters,
    deliveryStatus: DDeliveryStatus.delivered
  }).length;
  const totalDeliveries = deliverySelector(deliveryList, {
    ...omit(filters, ['deliveryStatus']),
  }).length;

  return (
    <>
      <TopBar
        onViewModeChange={setViewMode}
        viewMode={viewMode}
        deliveredDeliveries={delivered}
        totalDelvieries={totalDeliveries}
      />

      <Filters
        filters={filters}
        onFilter={setFilters}
      />
      <DeliveryContext.Provider value={{
        handleDelivered: delivery => setModal(delivery),
        handleDelivering,
        handleApplyPayment: delivery => setModal(delivery),
      }}>
        {(viewMode === ViewMode.map) ? (
          <DeliveriesMapView
            deliveries={deliveries}
            setSelected={id => dispatch({ type: 'select', payload: id })}
            selected={selected && keyedList[selected] || null}
          />
        ) : (
            <DeliveriesListView
              deliveries={deliveries}

            />
          )}
        <Modal
          visible={!!modal}
          onClose={() => setModal(null)}
        >
          {modal && (
            <NewPaymentForm
              delivery={modal}
              onCancel={() => setModal(null)}
              onSubmit={amount => handleModalSubmit(modal, amount)}
            />
          )}
        </Modal>
      </DeliveryContext.Provider>
    </>
  )

}

export default Deliveries;