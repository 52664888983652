import React from 'react';

interface PhoneNumberProps {
  children: string;
}

const PhoneNumber = ({ children }: PhoneNumberProps) => (
  <a href={`tel:${children}`}>{children}</a>
)

export default PhoneNumber;
