import React, { DetailedHTMLProps, HTMLAttributes, useContext } from 'react';
import { compact } from 'lodash-es';
import { DDeliveryStatus, DeliveryTime, NewDeliveryAddress } from '@calo/types';
import { AddressService } from '@calo/services';
import cx from 'classnames';
import { Delivery } from '@calo/driver-types';

import { PhoneNumber, Button } from '../../../components';
import ConfirmContext from '../../../components/Confirmation/ConfirmContext';
import DeliveryContext from '../DeliveryContext';




interface DeliveryRowProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  delivery: Delivery;
}

const DeliveryRow = ({ delivery, className, ...rest }: DeliveryRowProps) => {
  const confirm = useContext(ConfirmContext);
  const { handleDelivering, handleDelivered, handleApplyPayment } = useContext(DeliveryContext);


  return (
    <div className={cx('w-full flex flex-col bg-white shadow-lg rounded-lg overflow-hidden p-4', className)} {...rest}>
      <div className="flex flex-row justify-between items-center">
        <div className="text-gray-900 font-bold text-xl">{delivery.name}</div>
        <div>
          <span className={cx('text-xs px-2 py-1 rounded-sm', {
            'bg-gray-900 text-white': delivery.time === DeliveryTime.evening,
            'bg-gray-200': delivery.time !== DeliveryTime.evening,
          })}>{delivery.time || DeliveryTime.morning}</span>

          <a className="ml-4" target="_blank" href={`https://www.google.com/maps/dir/?api=1&destination=${delivery.deliveryAddress.lat},${delivery.deliveryAddress.lng}&dir_action=navigate&travelmode=driving`}>
            <i className="fas fa-map-marked-alt"></i>
          </a>
        </div>
      </div>
      <div className="mt-2 text-gray-600">
        <p><strong>Phone</strong>: <PhoneNumber>{delivery.phoneNumber}</PhoneNumber></p>
        <p><strong>Address</strong>: {AddressService.display(delivery.deliveryAddress as NewDeliveryAddress)}</p>
        <p><strong>Notes</strong>: {compact([
          delivery.deliveryAddress.notes,
        ]).join(', ')}</p>
      </div>
      <div className="flex item-center justify-between mt-3">
        <h1 className="text-gray-700 font-bold text-xl">{delivery.pendingAmount > 0 && `Pending: ${delivery.pendingAmount}`}</h1>
        {delivery.deliveryStatus !== DDeliveryStatus.delivering && delivery.deliveryStatus !== DDeliveryStatus.delivered && (
          <Button
            primary
            onClick={() => confirm({ confirm: handleDelivering.bind(null, delivery) })}
          >
            Pick for delivery
          </Button>
        )}
        {delivery.deliveryStatus === DDeliveryStatus.delivering && (
          <Button
            primary
            onClick={handleDelivered.bind(null, delivery)}
          >
            Mark as delivered
          </Button>
        )}
        {delivery.deliveryStatus === DDeliveryStatus.delivered && delivery.pendingAmount > 0 && (
          <Button
            primary
            onClick={handleApplyPayment.bind(null, delivery)}
          >
            Apply payment
          </Button>
        )}
      </div>
    </div>
  )
}

export default DeliveryRow;