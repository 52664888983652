import React from 'react';
import Auth from '@aws-amplify/auth';

import { ViewMode } from '../../lib/enums';

interface TopBarProps {
  viewMode: ViewMode;
  onViewModeChange: (viewMode: ViewMode) => void;
  totalDelvieries: number;
  deliveredDeliveries: number;
}

const TopBar = ({ totalDelvieries, deliveredDeliveries, viewMode, onViewModeChange }: TopBarProps) => {
  return (
    <nav className="shadow-md relative">
      <div className="absolute bg-green-500 top-0 left-0 bottom-0" style={{ width: `${deliveredDeliveries / totalDelvieries * 100}%` }} />
      <div className="flex items-center justify-between h-10 z-10 relative px-4">
        <div className="flex">
          {viewMode === ViewMode.list ? (
            <i className="fas fa-map-marked-alt" onClick={() => onViewModeChange(ViewMode.map)}></i>
          ) : (
              <i className="fas fa-list" onClick={() => onViewModeChange(ViewMode.list)}></i>
            )}
        </div>
        <div className="flex flex-geow items-center">
          {deliveredDeliveries} / {totalDelvieries}
        </div>
        <div className="flex items-center">
          <i className="fas fa-sign-out-alt" onClick={() => Auth.signOut()}></i>
        </div>
      </div>
    </nav>
  )
};

export default TopBar;