import axios from 'axios';
import Auth from '@aws-amplify/auth';
import { stringify } from 'qs';

const client = axios.create({
  baseURL: `${process.env.REACT_APP_API_URL}`,
  headers: {
    'Content-Type': 'application/json',
    // 'Accept': 'application/json'
  },
  paramsSerializer: (params) => {
    return stringify(params)
  },
});

client.interceptors.request.use(async options => {
  try {
    const session = await Auth.currentSession();

    options
      .headers['Authorization'] = `Bearer ${session.getIdToken().getJwtToken()}`;

    return options;

  } catch (error) {
    return {
      ...options,
      cancelToken: new axios.CancelToken(cancel => cancel())
    };
  }
}, error => Promise.reject(error));

export default client;
