import React, { DetailedHTMLProps, HTMLAttributes } from 'react';
import cx from 'classnames';

interface TabItemProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
  active?: boolean;
}

const TabItem = ({ active, children, ...rest }: TabItemProps) => (
  <div className={cx('px-2 py-4 border-b cursor-pointer', {
    'border-blue-500 text-blue-500': active
  })} {...rest}>{children}</div>
)

export default TabItem;