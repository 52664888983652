import client from '../lib/client';
import { LatLng } from '@calo/driver-types';


export const updateAddress = async (id: string, attr: LatLng) => {
  const { data } = await client.post(`/subscriptions/${id}/address`, attr);
  return data;
}

export const addToWallet = async (id: string, amount: number) => {
  const { data } = await client.post(`/subscriptions/${id}/wallet`, { amount });
  return data;
}