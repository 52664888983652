import React, { ReactNode, useEffect, useRef, ReactElement, useState, cloneElement, createContext } from 'react';
import RModal from 'react-modal';

interface ModalProps {
  children: ReactNode;

  trigger?: ReactElement;
  visible?: boolean;
  onClose?: () => void;
}


const Modal = ({ children, visible, onClose, trigger }: ModalProps) => {
  const [localVisible, setLocalVisible] = useState(false)

  const handleLocalClose = async () => {
    if (localVisible) {
      setLocalVisible(false);
    }
    if (onClose) {
      onClose()
    }
  }

  return (
    <>
      {trigger && cloneElement(trigger, {
        onClick: () => setLocalVisible(true)
      })}
      <RModal
        isOpen={visible || localVisible}
        onRequestClose={onClose}
        className="modal fixed w-full h-full top-0 left-0 flex items-center justify-center"
        overlayClassName=""
        bodyOpenClassName="overflow-hidden"
      >
        <div className="absolute w-full h-full bg-gray-900 opacity-50" />
        <div className="bg-white w-11/12 md:max-w-md mx-auto rounded shadow-lg z-50 overflow-y-auto p-4 outline-none">
          {children}
        </div>
      </RModal>
    </>
  )
}

export default Modal;
