
import React, { useState, useMemo, useEffect } from 'react';
import { DDeliveryStatus } from '@calo/types';
import { GoogleMap, Marker } from '@react-google-maps/api'
import { Delivery, DeliveryFilters } from '@calo/driver-types';

// import { useDeliveries } from '../../../hooks/delivery';
import DeliveryRow from '../DeliveryRow';
import { keyBy } from 'lodash-es';
import useMyLocation from './useMyLocation';


const defaultCenter = {
  lat: 26.222917,
  lng: 50.580958
}

interface DeliveriesMapViewProps {
  deliveries: Delivery[];
  selected: Delivery | null;
  setSelected: (id: string | null) => void
}

const DeliveriesMapView = ({ deliveries, selected, setSelected }: DeliveriesMapViewProps) => {
  const [map, setMap] = useState<google.maps.Map<Element>>();

  // const deliveriesData = useDeliveries({
  //   limit: 'ALL',
  //   filters
  // });

  useEffect(() => {
    if (selected && map) {
      map.panTo({ lat: selected.deliveryAddress.lat, lng: selected.deliveryAddress.lng })
    }
  }, [selected])

  useMyLocation(map)



  return (
    <>
     
      <GoogleMap
        onLoad={setMap}
        mapContainerClassName="flex flex-1"
        center={defaultCenter}
        zoom={13}
        options={{
          zoomControl: false,
          mapTypeControl: false,
          scaleControl: false,
          streetViewControl: false,
          fullscreenControl: false,
          styles: [{
            featureType: 'poi',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }]
          }, {
            featureType: 'transit.station.bus',
            elementType: 'labels',
            stylers: [{ visibility: 'off' }]
          }]
        }}
        onClick={() => setSelected(null)}
      >
        {deliveries.filter(d => d.deliveryAddress.lat && d.deliveryAddress.lng).map(delivery => (
          <Marker
            key={delivery.id}
            position={{
              lat: delivery.deliveryAddress.lat,
              lng: delivery.deliveryAddress.lng,
            }}
            onClick={() => setSelected(delivery.id)}
            label={delivery.priority.toString()}
          // icon={booleanPointInPolygon(point([delivery.deliveryAddress.lng, delivery.deliveryAddress.lat]), mpoly) ?
          //   'https://maps.google.com/mapfiles/ms/micons/green.png' :
          //   'https://maps.google.com/mapfiles/ms/micons/red.png'
          // }
          />
        ))}
        {selected && (
          <DeliveryRow
            key={selected.id}
            delivery={selected}
            className="absolute bottom-0 z-10"
          />
        )}
      </GoogleMap>
    </>
  )
}

export default DeliveriesMapView;