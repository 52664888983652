import React, { useState, useEffect } from 'react';

import { useCurrentUser } from '../../../lib/hooks';
import { Delivery } from '@calo/driver-types';

import DeliveryRow from '../DeliveryRow';

interface DeliveriesListViewProps {
  deliveries: Delivery[];
}
const DeliveriesListView = ({ deliveries }: DeliveriesListViewProps) => {
  return (
    <div className="container p-4">
      {deliveries.map(delivery => (
        <DeliveryRow
          key={delivery.id}
          delivery={delivery}
          className="mb-4"
        />
      ))}
    </div>
  )
}

export default DeliveriesListView;