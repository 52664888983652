import React, { DetailedHTMLProps, ButtonHTMLAttributes, InputHTMLAttributes } from 'react';
import cx from 'classnames';

interface InputProps extends DetailedHTMLProps<InputHTMLAttributes<HTMLInputElement>, HTMLInputElement> {
  label?: string;
}

const Input = ({ className, label, ...rest }: InputProps) => (
  <div className="w-full mb-4 last:mb-0">
    {label && (
      <p className="text-sm mb-2 text-gray-600">{label}</p> 
    )}
    <input className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal" {...rest}/>
  </div>
)

export default Input;
