import { DeliveryQuery } from '@calo/driver-types';

import client from '../lib/client';
export * from './delivery';
export * from './subscription';

export const getList = async (key: string, query: DeliveryQuery) => {
  const { data } = await client.get(key, {
    params: query
  });
  
  return data.data;
}