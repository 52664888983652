import React from 'react';
import { omit } from 'lodash-es';
import { DeliveryFilters } from '@calo/driver-types';
import cx from 'classnames';
import { DDeliveryStatus, DeliveryTime } from '@calo/types';
import { format, parseISO, eachDayOfInterval, addWeeks, isSameDay, addDays } from 'date-fns/fp';
import { Tabs, TabItem } from '../../../components';

interface FiltersProps {
  onFilter: (values: DeliveryFilters) => any;
  filters: DeliveryFilters;
}

const Filters = ({ filters, onFilter }: FiltersProps) => {
  return (
    <div className="container">
      <div className="flex flex-row justify-between">
        <div>
          <Tabs>
            <TabItem
              onClick={() => onFilter({ ...omit(filters, ['deliveryStatus']) })}
              active={!filters.deliveryStatus}
            >
              New
            </TabItem>
            <TabItem
              onClick={() => onFilter({ ...filters, deliveryStatus: DDeliveryStatus.delivered })}
              active={filters.deliveryStatus === DDeliveryStatus.delivered}
            >
              Delivered
            </TabItem>

          </Tabs>
          <div className="tabs">
            {/* <ul>
              <li className={cx({ 'is-active':  })}>
                <a >New</a>
              </li>
              <li className={cx({ 'is-active': f })}>
                <a ></a>
              </li>
            </ul> */}
          </div>
        </div>
        <div>
          <Tabs>
            <TabItem
              onClick={() => onFilter({ ...filters, deliveryTime: DeliveryTime.morning })}
              active={filters.deliveryTime === DeliveryTime.morning}
            >
              Morning
              </TabItem>
            <TabItem
              onClick={() => onFilter({ ...filters, deliveryTime: DeliveryTime.evening })}
              active={filters.deliveryTime === DeliveryTime.evening}
            >
              Evening
            </TabItem>

          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default Filters;