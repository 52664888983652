import React, { ReactNode, useEffect, useRef, useState, cloneElement, ReactElement, useCallback } from 'react';
import { Modal } from '..';
import Button from '../Button';
import ConfirmContext from './ConfirmContext';

interface ConfirmationProps {
  children: ReactElement;
}

const Confirmation = ({ children }: ConfirmationProps) => {
  const [isModalVisible, setModalVisible] = useState(false);
  const [callback, setCallback] = useState<ConfirmProps>();


  const confirmation = useCallback((callback) => {
    setModalVisible(true);
    setCallback(callback)
  }, []);

  const handleConfirm = async () => {
    if (callback) {
      const { confirm } = callback;
      await confirm();
    }
    setModalVisible(false);
  }

  return (
    <>
      <ConfirmContext.Provider value={confirmation}>
        {children}
      </ConfirmContext.Provider>
      <Modal
        visible={isModalVisible}
        onClose={() => setModalVisible(false)}

      >
        <div className="flex flex-col">
          {callback?.text && (
            <div className="mb-4">{callback?.text}</div>
          )}
          <div className="flex flex-row justify-between">
            <Button onClick={() => setModalVisible(false)}>Cancel</Button>
            <Button primary onClick={handleConfirm}>Confirm</Button>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Confirmation;
