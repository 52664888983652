import React, { useEffect, useState, DetailedHTMLProps, HTMLAttributes, useContext } from 'react';
import { compact } from 'lodash-es';
import { DeliveryStatus, PaymentMethod, DDeliveryStatus, DeliveryTime } from '@calo/types';
import cx from 'classnames';
import { Delivery } from '@calo/driver-types';
import { PhoneNumber, Confirmation, Button, Input } from '../../../components';
import ConfirmContext from '../../../components/Confirmation/ConfirmContext';
import DeliveryContext from '../DeliveryContext';




interface NewPaymentFormProps {
  delivery: Delivery;
  onCancel: () => any;
  onSubmit: (amount: number) => any;
}

const NewPaymentForm = ({ delivery, onSubmit, onCancel }: NewPaymentFormProps) => {
  const [amount, setAmount] = useState(0);

  return (
    <div className="flex flex-col">
      {delivery.pendingAmount > 0 && (
        <Input
          label="Amount"
          type="number"
          value={amount}
          onChange={e => setAmount(parseFloat(e.target.value))}
        />
      )}
      <div className="flex flex-row justify-between">
        <Button onClick={onCancel}>Cancel</Button>
        <Button primary onClick={() => onSubmit(amount)}>Confirm</Button>
      </div>
    </div>
  )
}

export default NewPaymentForm;