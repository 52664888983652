import React, { useState, DetailedHTMLProps, ButtonHTMLAttributes } from 'react';
import cx from 'classnames';

interface ButtonProps extends DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement> {
  primary?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => void | Promise<void>
}

const Button = ({ className, children, primary, onClick, disabled, ...rest }: ButtonProps) => {
  const [loading, setLoading] = useState(false);
  const handleClick = (event: React.MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    if (onClick) {
      const res = onClick(event);
      if (res instanceof Promise) {
        setLoading(true)
        res.finally(() => loading && setLoading(false))
      }
    }

  }
  return (
    <button
      className={cx('shadow focus:outline-none text-xs px-4 py-2 rounded font-bold', {
        'bg-green-500 hover:bg-green-700 text-white': primary,
        'opacity-75': loading
      })}
      type="button"
      onClick={handleClick}
      disabled={disabled || loading}
      {...rest}
    >
      {children}
    </button>

  )
}
export default Button;