import { Reducer, useMemo } from "react";
import { Delivery, DeliveryFilters } from "@calo/driver-types";
import { DDeliveryStatus } from "@calo/types";



export default (deliveries: Delivery[], filters: DeliveryFilters) => useMemo(() => 
  deliveries.filter(d => 
    d.time === filters.deliveryTime && 
    (filters.deliveryStatus === DDeliveryStatus.delivered ? d.deliveryStatus === DDeliveryStatus.delivered : d.deliveryStatus !== DDeliveryStatus.delivered)
  ), [deliveries, filters])
