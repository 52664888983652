import React, { DetailedHTMLProps, HTMLAttributes } from 'react';

interface TabsProps extends DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> {
}


const Tabs = ({ children, ...rest }: TabsProps) => {
  return (
    <div className="flex flex-row" {...rest}>{children}</div>
  )
};

export default Tabs;