import { Reducer } from "react";
import { Delivery } from "@calo/driver-types";
import { Dictionary, DDeliveryStatus } from "@calo/types";
import { keyBy, rest } from "lodash-es";

type Action = {
  type: 'set' | 'update' | 'select',
  payload?: any;
}

type State = {
  list: string[];
  keyedList: Dictionary<Delivery>;
  selected: string | null;
}


const reducer: Reducer<State, Action> = (state = { list: [], keyedList: {}, selected: null }, action) => {
  switch (action.type) {
    case 'set':
      return {
        ...state,
        list: [...action.payload.map((r: Delivery) => r.id)],
        keyedList: keyBy(action.payload, 'id')
      }
    case 'select':
      if (action.payload) {
        return {
          ...state,
          selected: action.payload
        }
      }
      return {
        ...state,
        selected: null
      }
    case 'update':
      const { id, ...rest } = action.payload;
      return {
        ...state,
        keyedList: {
          ...state.keyedList,
          [id]: {
            ...state.keyedList[id],
            ...rest
          }
        },
        ...(rest.deliveryStatus === DDeliveryStatus.delivered && {
          selected: null
        })
      }
    default:
      return state;
  }
}

export default reducer
