import React, { Suspense } from 'react';
import Auth from '@aws-amplify/auth';
import { Router, Route, Redirect } from 'react-router';
import { withAuthenticator, SignIn, RequireNewPassword, ForgotPassword } from 'aws-amplify-react/lib/Auth';
import { ToastContainer } from 'react-toastify';


import { Routes } from '../lib/enums';
import { Deliveries } from '../views';
import history from '../lib/history';
import { Confirmation } from '../components';

Auth.configure({
  region: process.env.REACT_APP_AWS_REGION,
  userPoolId: process.env.REACT_APP_AWS_USER_POOL,
  userPoolWebClientId: process.env.REACT_APP_AWS_USER_POOL_CLIENT,
});

const App = () => (
  <>
    <Confirmation>
        <Router history={history}>
          <Suspense fallback={<section className="element is-loading" />}>
            <Route exact path={Routes.root} component={Deliveries} />
            <Redirect to={Routes.root} />
          </Suspense>
        </Router>
    </Confirmation>
    <ToastContainer />
  </>
)

export default withAuthenticator(App, false, [<SignIn />, <RequireNewPassword />, <ForgotPassword />]);
